import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const SecondSection = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'All your',
        es: 'Todas'
    }    
    const title = {
        en: 'reservations well organized',
        es: 'tus reservaciones bien organizadas'
    }
    
    return (
        <ThreeColumns className='fluid'>
            <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/reservations-2.png"
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt="reservations-2"
                    className="img-container overflow-fluid-md"
                    imgClassName="overflowed-img"
                />
            </div>

            <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                <h2><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                <p>
                    {translateObject({
                        en: `Having different status for your reservations will make it easier to have control of 
                        each one of them and through the use of notifications your team will not miss any change 
                        of status in the reservations, so they will know exactly what they have to do depending 
                        on the situation and your clients they will always be well cared for.`,
                        es: `Reservations permite tener diferentes estados en las reservaciones para facilitar el control de
                        cada una de ellas. Con esto y el uso de notificaciones tu equipo no se perderá ningún cambio
                        de estado en las reservaciones, así sabrán exactamente lo que tienen que hacer en cada 
                        situación y tus clientes siempre estarán bien atendidos.`
                    })}
                </p>
            </div>

            <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/reservations-3.png"
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt="reservations-3"
                    className='img-container'
                    imgClassName='overflowed-img'
                />
            </div>
        </ThreeColumns>
    )
};

export default SecondSection;