import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import { graphql, useStaticQuery } from "gatsby";
import Description from "../sections/Products/Reservations/description";
import SecondSection from "../sections/Products/Reservations/second-section";
import '../globalStyles.css';

const Reservations = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 6,
    title: 'Reservations',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[4].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[4].node.base,
    heroMobile: datainfo[4].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[4].node.base,
    banerImg: datainfo[3].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[3].node.base,
    banerImgMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[3].node.base,
    features: [
      {
        en: 'Create unique links for your customers to confirm their reservations',
        es: 'Crea links únicos para que tus clientes puedan confirmar su reserva de forma segura'
      },
      {
        en: 'Keep track of your reservations',
        es: 'Mantén un seguimiento de las reservaciones'
      },
      {
        en: 'Control reservations through different stages, from creating links to confirm reservations to confirming that the client has presented to the reservation',
        es: 'Controla las reservaciones a través de diferentes etapas. Crea links para que tus clientes puedan confirmar su reserva y confirma su asistencia'
      },
      {
        en: 'Indicates if a client did not show up for their reservation and applies the corresponding penalty charges',
        es: 'Indica su un cliente no llegó a su reserva y aplica el correspondiente cargo de penalización'
      },
      {
        en: 'Keep track of penalty charges that are pending to be applied',
        es: 'Da seguimiento a los cargos de penalización que estén pendientes por ser aplicados'
      },
      {
        en: 'Review disputes generated when a client does not recognize a penalty charge',
        es: 'Entérate de disputas ocasionadas por clientes que desnocen los cargos de penalización'
      },
      {
        en: 'Receive notifications so as not to lose any change of status',
        es: 'Recibe notificaciones para estar siempre enterado de cualquier cambio'
      }
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title="Reservations" />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <SecondSection />
      </Section>
    </ProductLayout >
  );
};
export default Reservations;

const query = graphql`
query reservations {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "reservations-hero",
          "reservations-icon",
          "reservations-banner",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;