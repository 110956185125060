import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import {
    Row,
    Space,
    Center,
    Container,
    TwoColumns,
    InfoContainer,
    ContainerFluid,
    ImageContainer
} from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => {
    
    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Book your',
        es: 'Agenda'
    }    
    const title = {
        en: 'your costumers',
        es: 'a tus clientes'
    }
    
    return (
        <ContainerFluid>
            <Container>
                <Space />
                <Position location={location} />
                <Center>
                    <h2 data-sal="slide-up" data-sal-easing="ease-in-circ"><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                    <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                        {translateObject({
                            en: 'Have better control over the capacity of your business through a tool to create and manage reservations.',
                            es: 'Ten un mejor control de la capacidad de tu negocio con esta herramieta para crear y administrar reservas.'
                        })}                        
                    </p>
                </Center>
            </Container>
            <TwoColumns>
                <Row>
                    <Container className="fluid">
                        <InfoContainer>
                            <div data-sal='slide-left' data-sal-easing="ease-in-circ">
                                <Space />
                                <h3>
                                    {translateObject({
                                        en: `Create and manage bookings`,
                                        es: `Crea y administra la reservaciones`
                                    })}                                    
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `Let your host team have a complete overview of your client’s reservations. 
                                        They can track each of the different statuses and charge penalties for customers who do not show up for their reservations.`,
                                        es: `Brinda a tu equipo anfitrión una vista general de las reservaciones para poder dar seguimiento
                                        a cada una de ellas y a los cargos por penalización de los clientes que no asistieron a sus reservaciones.`
                                    })}
                                    
                                </p>
                            </div>

                            <div data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-delay='300'>
                                <h3>
                                    {translateObject({
                                        en: `For host team, for customers`,
                                        es: `Para tu equipo anfitrión y para clientes`
                                    })}                                    
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `A business tool to create and follow up reservations; 
                                        and a platform for your customers to confirm, modify or cancel their reservations.`,
                                        es: `Una herramienta empresarial para crear y dar seguimiento a las reservas;
                                        y una plataforma para tus clientes para confirmar, modificar o cancelar sus reservaciones.`
                                    })}
                                </p>
                            </div>
                        </InfoContainer>
                    </Container>                
                </Row>
                <ImageContainer data-sal='slide-right' data-sal-easing="ease-in-circ" data-sal-duration='1000'>
                    <StaticImage
                        src="../../../images/reservations-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="reservations-1"
                        className='mask-container'
                        imgClassName='img-fluid'                   
                    />
                </ImageContainer>
            </TwoColumns>
        </ContainerFluid>
    )
};

export default Description;